import {
  fromNavigator,
  fromStorage,
  fromUrl,
  multipleDetect,
} from "@lingui/detect-locale";
import { LoaderFunctionArgs, redirect } from "react-router-dom";
import { z } from "zod";
import { bbCollectionIdSchema } from "~/bbCollectionIdSchema";
import { graphql } from "~/gql";
import graphQLClient from "~/graphQLClient";

const paramsSchema = z.object({
  collectionId: z.string(),
  "*": z.string(),
});

export async function Loader(args: LoaderFunctionArgs) {
  const params = paramsSchema.parse(args.params);
  const { collectionId: collectionIdSegment } = params;
  const collectionId = bbCollectionIdSchema.parse(collectionIdSegment);
  const result = await graphQLClient.request(
    graphql(/* GraphQL */ `
      query CollectionLocale($collectionId: ID!) {
        collection(collectionId: $collectionId) {
          collectionId
          locales(format: RAW)
        }
      }
    `),
    { collectionId },
    { "bottlebooks-use-request-cache": "true" }
  );
  const locales = result.collection?.locales ?? [];

  const detectedLocales = multipleDetect(
    fromUrl("locale"),
    fromStorage("locale"),
    fromNavigator(),
    locales[0]
  );
  const locale =
    detectedLocales.find((locale) => locales.includes(locale)) ?? locales[0];

  return redirect(
    `/${locale}/collections/${collectionIdSegment}/${params["*"]}`
  );
}
